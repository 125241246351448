import { useState } from 'react';
import { FunctionField } from 'react-admin';
import { Chip } from '@mui/material';

const PrayerField = ({ label }) => {
    const [text, setText] = useState('');

    return (
        <FunctionField
            label={label}
            render={(record) => {
                switch (record.name) {
                    case 'users': {
                        setText('Користувачі');
                        break;
                    }
                    case 'videos': {
                        setText('Ефіри та записи');
                        break;
                    }
                    case 'filters': {
                        setText('Фільтри');
                        break;
                    }
                    case 'meetings': {
                        setText('Недільні зустрічі');
                        break;
                    }
                    case 'connect_group': {
                        setText('Заявки до конект групи');
                        break;
                    }
                    case 'connect_group_list': {
                        setText('Конект групи');
                        break;
                    }
                    case 'volontier_group': {
                        setText('Заявки до волонтерської групи');
                        break;
                    }
                    case 'prayers': {
                        setText('Модерація Молитовної стіни');
                        break;
                    }
                    case 'alerts_to_coach': {
                        setText('Заявки до пасторів');
                        break;
                    }
                    case 'roles': {
                        setText('Ролі');
                        break;
                    }
                    case 'roles_names': {
                        setText('Назви ролей');
                        break;
                    }
                    case 'all': {
                        setText('Доступ до всіх таблиць');
                        break;
                    }
                    default: {
                        setText('Помилка');
                        break;
                    }
                }

                return (
                    <Chip
                        label={text}
                        sx={{
                            background: 'gray',
                            borderRadius: 2,
                            color: 'white',
                            fontWeight: 600,
                        }}
                    />
                )
            }}
        />
    );
};

export default PrayerField;
