import {
  Alert,
  Box,
  Link,
  ListItemIcon,
  Button as MuiButton,
  Tab,
  Tabs,
  Typography,
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { FC, useEffect, useRef, useState } from "react";
import {
  BooleanInput,
  Button,
  DateTimeInput,
  Edit,
  ImageField,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useDataProvider,
  useRecordContext,
  useRedirect,
  useUpdate,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UploadVideoSnippet, download, storageRef } from "../../lib/firebase";
import NotificationsTab from "./tabs/notifications_tab";

const CustomToolBar: FC<{ blob: Blob | null; blobEn: Blob | null }> = ({
  blob,
  blobEn,
}) => {
  const form = useFormContext();
  // const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const redirect = useRedirect();

  const SaveHandler = async () => {
    try {
      const data = form.getValues();
      const { id } = data;
      delete data.id;

      console.log(data);

      const generateBannerString = () => {
        const id = Math.random().toString(36).substring(2, 12);
        return `Banner${id}`;
      };

      if (blob) {
        const snapshot = await UploadVideoSnippet(
          data.name.replace(/\s/g, "") || generateBannerString(),
          blob,
        );
        data.cover = await download(storageRef(snapshot.metadata.fullPath));
      }

      if (blobEn) {
        const snapshot = await UploadVideoSnippet(
          data.name_en.replace(/\s/g, "") || generateBannerString(),
          blobEn,
        );
        data.cover_en = await download(storageRef(snapshot.metadata.fullPath));
      }

      if (data) {
        await update("events", { id, data });
        redirect("/events");
        return;
      }

      await update("events", { id, data });
      redirect("/events");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        size="medium"
        disabled={isLoading}
        label="Зберегти"
        onClick={SaveHandler}
      />
    </Toolbar>
  );
};

const EventTitle = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

interface Participant {
  id: string;
  participantId: string;
  eventId: string;
}

interface User {
  id: string;
  name: string;
  photo?: {
    photo_url: string;
  };
  fcm_token?: string;
}

const ParticipantsList: React.FC = () => {
  const dataProvider = useDataProvider();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const { id: eventId } = useRecordContext<{ id: string }>();
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    const fetchParticipants = async () => {
      const { data } = await dataProvider.getList<Participant>(
        "registered_participants",
        {
          filter: { eventId },
          pagination: { page: 1, perPage: 100000 },
          sort: { field: "id", order: "ASC" },
        },
      );
      setParticipants(data);
    };

    if (eventId) {
      fetchParticipants();
    }
  }, [eventId, dataProvider]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (participants.length > 0) {
        const userIds = participants.map(
          (participant) => participant.participantId,
        );
        const { data } = await dataProvider.getMany<User>("users", {
          ids: userIds,
        });
        setUsers(data);
      }
    };

    fetchUsers();
  }, [participants, dataProvider]);

  const getUserName = (userId: string): string => {
    const user = users.find((user) => user.id === userId);
    return user ? user.name : "Unknown User";
  };

  const getUserPhoto = (userId: string): string => {
    const user = users.find((user) => user.id === userId);
    return user && user.photo?.photo_url
      ? user.photo.photo_url
      : "/imgs/avatars/1-male.svg";
  };

  const hasApp = (userId: string): boolean => {
    const user = users.find((user) => user.id === userId);
    return Boolean(user && user.fcm_token);
  };

  const usersWithApp = participants.filter((participant) =>
    hasApp(participant.participantId),
  );
  const usersWithoutApp = participants.filter(
    (participant) => !hasApp(participant.participantId),
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const renderUserList = (userList: Participant[]) => (
    <List>
      {userList.map((participant) => (
        <ListItem key={participant.id} disablePadding>
          <ListItemButton
            onClick={() => navigate(`/users/${participant.participantId}`)}
            sx={{ p: 1, pl: 3, gap: 3 }}
          >
            <ListItemIcon>
              <img
                style={{ height: 40, width: 40, borderRadius: "50%" }}
                src={getUserPhoto(participant.participantId)}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={getUserName(participant.participantId)} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ width: "100%", pt: 2, pb: 2 }}>
      <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: 2 }}>
        Учасники події
      </Typography>
      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label={`З додатком (${usersWithApp.length})`} />
        <Tab label={`Без додатку (${usersWithoutApp.length})`} />
      </Tabs>
      {tabIndex === 0 && (
        <>
          <Typography variant="subtitle1">
            Користувачі, які можуть отримувати push-повідомлення:
          </Typography>
          {renderUserList(usersWithApp)}
        </>
      )}
      {tabIndex === 1 && (
        <>
          <Typography variant="subtitle1">
            Користувачі, які не можуть отримувати push-повідомлення:
          </Typography>
          {renderUserList(usersWithoutApp)}
        </>
      )}
    </Box>
  );
};

const EventEdit = () => {
  const [blob, setBlob] = useState<Blob | null>(null);
  const [blobEN, setBlobEN] = useState<Blob | null>(null);
  const [blobError, setBlobError] = useState<string>("");
  const [tabIndex, setTabIndex] = useState(0);

  const ImageInputRef = useRef<HTMLInputElement>(null);
  const ImageInputRefEN = useRef<HTMLInputElement>(null);

  const handleUpload = (event: any) => {
    const img = new Image();
    img.src = URL.createObjectURL(event.target.files[0]);

    img.onload = () => {
      const { height, width } = img;

      if ((width / height).toFixed(2) !== "1.78") {
        setBlobError("Додайте зображення з відношенням сторін 16 на 9");
        return;
      } else if (
        height < 720 ||
        height > 1080 ||
        width < 1280 ||
        width > 1920
      ) {
        setBlobError(
          "Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів",
        );
        return;
      } else {
        setBlobError("");
        setBlob(event.target.files[0]);
      }
    };
  };

  const handleUploadEN = (event: any) => {
    const img = new Image();
    img.src = URL.createObjectURL(event.target.files[0]);

    img.onload = () => {
      const { height, width } = img;

      if ((width / height).toFixed(2) !== "1.78") {
        setBlobError("Додайте зображення з відношенням сторін 16 на 9");
        return;
      } else if (
        height < 720 ||
        height > 1080 ||
        width < 1280 ||
        width > 1920
      ) {
        setBlobError(
          "Зображення повинно бути не менше 1280x720 та не більше 1920х1080 пікселів",
        );
        return;
      } else {
        setBlobError("");
        setBlobEN(event.target.files[0]);
      }
    };
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Edit title={<EventTitle />}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="event edit tabs"
        >
          <Tab label="Редагування події" />
          <Tab label="Учасники подій та Розсилка" />
        </Tabs>
      </Box>
      {tabIndex === 0 && (
        <SimpleForm toolbar={<CustomToolBar blob={blob} blobEn={blobEN} />}>
          <Box display="flex" flex="row" gap={5} width="100%">
            <TextInput
              multiline
              fullWidth
              label="Назва Українською"
              source="name"
            />
            <TextInput
              multiline
              fullWidth
              label="Назва Англійською"
              source="name_en"
            />
          </Box>

          <Box display="flex" flex="row" gap={5} width="100%">
            <TextInput
              multiline
              fullWidth
              label="Опис Українською"
              source="description"
            />
            <TextInput
              multiline
              fullWidth
              label="Опис Англійською"
              source="description_en"
            />
          </Box>

          <Box display="flex" flex="row" alignItems="center" width="100%">
            <BooleanInput label="Показується" source="shown" />
            <DateTimeInput
              validate={required()}
              label="Дата проведення"
              source="date"
            />
          </Box>

          <Box display="flex" flexDirection="row" width="100%">
            <TextInput fullWidth label="Посилання" source="link" />
          </Box>

          <Box display="flex" flexDirection="column" width="100%">
            <h4>Поля для застосунку</h4>
            <TextInput fullWidth label="Відео ID" source="video_id" />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            sx={{ mt: 3 }}
            alignItems="center"
          >
            <Box>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={ImageInputRef}
                onChange={handleUpload}
              />

              <Box sx={{ width: "70%" }}>
                {blob ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={URL.createObjectURL(blob)}
                    alt={""}
                  />
                ) : (
                  <ImageField
                    sx={{
                      "& .RaImageField-image": {
                        margin: 0,
                        width: "100%",
                        height: "100%",
                      },
                    }}
                    title="video-snippet"
                    source="cover"
                  />
                )}

                {blobError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {blobError}
                  </Alert>
                )}

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <MuiButton
                    variant="contained"
                    sx={{
                      background: "#703eff",
                      lineHeight: "18px",
                      "&:hover": {
                        background: "#703eff",
                      },
                    }}
                    onClick={() => {
                      if (ImageInputRef.current) {
                        ImageInputRef.current.click();
                      }
                    }}
                  >
                    Змінити картинку
                  </MuiButton>
                  <Typography
                    sx={{
                      fontSize: 14,
                      textAlign: "end",
                      width: "70%",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {
                      "Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів"
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/*Cover EN*/}
            <Box>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={ImageInputRefEN}
                onChange={handleUploadEN}
              />

              <Box sx={{ width: "70%" }}>
                {blobEN ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={URL.createObjectURL(blobEN)}
                    alt={""}
                  />
                ) : (
                  <ImageField
                    sx={{
                      "& .RaImageField-image": {
                        margin: 0,
                        width: "100%",
                        height: "100%",
                      },
                    }}
                    title="video-snippet"
                    source="cover_en"
                  />
                )}

                {blobError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {blobError}
                  </Alert>
                )}

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={2}
                >
                  <MuiButton
                    variant="contained"
                    sx={{
                      background: "#703eff",
                      lineHeight: "18px",
                      "&:hover": {
                        background: "#703eff",
                      },
                    }}
                    onClick={() => {
                      if (ImageInputRefEN.current) {
                        ImageInputRefEN.current.click();
                      }
                    }}
                  >
                    Змінити картинку EN
                  </MuiButton>
                  <Typography
                    sx={{
                      fontSize: 14,
                      textAlign: "end",
                      width: "70%",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {
                      "Зображення повинно мати співвідношення сторін 16:9,\n бути не менше 1280x720 та не більше 1920х1080 пікселів"
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </SimpleForm>
      )}
      {tabIndex === 1 && (
        <Box display="flex" flexDirection="row" width="100%" p={2} gap={5}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"stretch"}
            width="100%"
          >
            <ParticipantsList />
          </Box>

          <Box sx={{ borderRight: 1, borderColor: "divider" }}></Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems={"stretch"}
            width="100%"
          >
            <NotificationsTab />
          </Box>
        </Box>
      )}
    </Edit>
  );
};

export default EventEdit;
