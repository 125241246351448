import {
  Button,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useCreate,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin'

import { Alert, Button as MuiButton } from '@mui/material'
import Box from '@mui/material/Box'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { FC, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  UploadVideoSnippet,
  db,
  download,
  storageRef,
} from '../../lib/firebase'

const CustomToolBar: FC<{
  logoSrc: string
  logoBlob: Blob | null
}> = ({ logoSrc, logoBlob }) => {
  const { getValues } = useFormContext()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [update] = useUpdate(undefined, undefined, {})

  const [create, { isLoading, data }] = useCreate(undefined, undefined, {
    onSuccess(data, variables, context) {
      console.log('data 1', data)
      handleCreateConversation(
        data.id,
        data.logo,
        data.name,
        data.name_en,
        data.main_coach_id,
        update
      )
    },
  })

  const CreateHandler = async () => {
    const { name, name_en, main_coach_id } = getValues()

    let logo = logoSrc || ''

    if (logoBlob) {
      const snapshot = await UploadVideoSnippet(
        name.replace(/\s/g, ''),
        logoBlob
      )
      logo = await download(storageRef(snapshot.metadata.fullPath))
    }

    if (!name) {
      notify('Введіть назву українською', { type: 'error' })
      return
    } else if (!name_en) {
      notify('Введіть назву англійською', { type: 'error' })
      return
    } else if (!logo) {
      notify('Виберіть логотип', { type: 'error' })
      return
    }

    try {
      create('connect_group_list', {
        data: { name, name_en, main_coach_id, logo },
      })
      redirect('/connect_group_list')

      setTimeout(() => {
        refresh()
      }, 1000)
    } catch (e) {
      notify('Помилка!', { type: 'error' })
      console.log(e)
    }
  }

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        label='Зберегти'
        disabled={isLoading}
        size='medium'
        onClick={CreateHandler}
      />
    </Toolbar>
  )
}

const handleCreateConversation = async (
  id: string,
  logo: string,
  name: string,
  name_en: string,
  coachId: string,
  update: any
) => {
  try {
    // Create an array with the coachId
    const usersArray = [coachId]

    // Update coach coach_connect_id in Hasura DB
    await update('users', {
      id: coachId,
      data: { coach_connect_id: id },
    })

    // Set the document with the correct structure
    await setDoc(doc(db, 'conversations', id), {
      group: {
        groupId: id,
        coachName: '',
        coachId,
        connectGroupName: name,
        connectGroupNameEN: name_en,
        groupImage: logo,
      },
      coach: {
        id: coachId,
      },
      createdAt: new Date().toISOString(),
      updatedAt: serverTimestamp(),
      seen: {},
      users: usersArray, // Pass the array of user IDs
    })
  } catch (error) {
    console.error('Error creating conversation: ', error)
  }
}

const CoachInput = () => {
  const [coachUsers, setCoachUsers] = useState([])
  const dataProvider = useDataProvider()

  useEffect(() => {
    dataProvider
      .getList('users', {
        pagination: {
          page: 1,
          perPage: 100000,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
        filter: undefined,
      })
      .then(({ data }) => {
        console.log('data', data)
        const coachUsers = data.filter(
          el => el.access_level === 'coach' && el.connect_id === null
        )

        const array: any = []

        for (const coach of coachUsers) {
          array.push({ id: coach.id, name: coach.name })
        }

        setCoachUsers(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <SelectInput
      sx={{ ml: 3, width: '42%' }}
      label='Лідер'
      source='main_coach_id'
      choices={coachUsers}
    />
  )
}

const ConnectGroupCreate = () => {
  const [logoSrc, setLogoSrc] = useState('')
  const [logoBlob, setLogoBlob] = useState<Blob | null>(null)

  const [blobError, setBlobError] = useState<string>('')

  const LogoInputRef = useRef<HTMLInputElement>(null)

  const handleUploadLogo = (event: any) => {
    const img = new Image()
    img.src = URL.createObjectURL(event.target.files[0])

    img.onload = () => {
      const { height, width } = img
      setLogoSrc(img.src)
      setLogoBlob(event.target.files[0])
    }
  }

  return (
    <Create title='Створити конект групу'>
      <SimpleForm
        toolbar={
          <CustomToolBar logoSrc={logoSrc ?? null} logoBlob={logoBlob} />
        }
      >
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flex='row' width='100%'>
            <TextInput
              sx={{ ml: 3 }}
              label='Назва конект групи Укр'
              source='name'
              validate={required()}
            />
            <TextInput
              sx={{ ml: 3 }}
              label='Назва конект групи Eng'
              source='name_en'
              validate={required()}
            />
          </Box>
          <Box display='flex' flex='row' width='100%'>
            <CoachInput />
          </Box>

          <Box sx={{ ml: 3 }}>
            <input
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              ref={LogoInputRef}
              onChange={handleUploadLogo}
            />
            <img
              style={{ width: '100%', height: '100%' }}
              src={logoBlob ? URL.createObjectURL(logoBlob) : ''}
              alt='photo'
            />

            {blobError && (
              <Alert severity='error' sx={{ mb: 2 }}>
                {blobError}
              </Alert>
            )}

            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
            >
              <MuiButton
                variant='contained'
                sx={{
                  background: '#703eff',
                  lineHeight: '18px',
                  '&:hover': {
                    background: '#703eff',
                  },
                }}
                onClick={() => {
                  if (LogoInputRef.current) {
                    LogoInputRef.current.click()
                  }
                }}
              >
                Змінити лого
              </MuiButton>
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}

export default ConnectGroupCreate
