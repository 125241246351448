import { FC } from 'react'
import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FormTab,
  List,
  ReferenceField,
  ReferenceManyField,
  TabbedForm,
  TabbedFormTabs,
  TextField,
  TopToolbar,
  useRecordContext
} from 'react-admin'
import BulkActionButtons from '../../components/common/BulkActionButtons'

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="Створити" />
    <ExportButton label="Експорт" />
  </TopToolbar>
);

const MainCoachField:FC<{label: string}> = ({label}) => {
    const { main_coach_id } = useRecordContext();

    if ( !main_coach_id ) {
        return <p> Не вказан </p>
    } else
    return (
        <ReferenceField label="Лідер" source="main_coach_id" reference="users">
            <TextField source="name" />
        </ReferenceField>
    )
}
const ConnectGroupList = () => {
  return (
    <List title="Конект групи" sx={{ tableLayout: 'fixed' }} actions={<ListActions />}>
        <TabbedForm tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} syncWithLocation={false}>
            <FormTab label="Конект групи">
              <Datagrid
                bulkActionButtons={<BulkActionButtons />}
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f5f5',
                }}
                rowClick="edit"
              >
                <TextField label="Назва" source="name" />
                <DateField label="Дата створення" source="created_at" />

                  <MainCoachField label="Лідер" />

                  <ReferenceManyField label="Пастори" reference="users" target="connect_id" filter={{ access_level: 'coach' }}>
                      <Datagrid bulkActionButtons={false}>
                          <TextField label="ПІБ" source="name" />
                          <TextField label="Пошта" source="email" />
                          <TextField label="Телефон" source="phone" />
                      </Datagrid>
                  </ReferenceManyField>
              </Datagrid>
            </FormTab>
            <FormTab label="Користувачі">
                <Datagrid bulkActionButtons={false} sx={{width: '100%'}}>
                    <TextField label="Назва" source="name" />
                    <ReferenceManyField label="Користувачі" reference="users" target="connect_id">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="ПІБ" source="name" />
                            <TextField label="Пошта" source="email" />
                            <TextField label="Телефон" source="phone" />
                        </Datagrid>
                    </ReferenceManyField>
                </Datagrid>
            </FormTab>
        </TabbedForm>
    </List>
  );
};

export default ConnectGroupList;
