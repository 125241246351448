import { Box, Stack } from '@mui/material'
import {
	BulkDeleteButton,
	Datagrid,
	DateField,
	EmailField,
	ExportButton,
	FilterButton,
	FilterForm,
	List,
	ReferenceField,
	SelectInput,
	TextField,
	TextInput,
	TopToolbar,
	required,
} from 'react-admin'

import StatusField from '../../components/common/StatusField'
import UpdateStatusButton from '../../components/common/UpdateStatusButton'

const postFilters = [
	<SelectInput
		label='За статусом'
		source='status'
		validate={required()}
		choices={[
			{ id: 'pending', name: 'Очікування' },
			{ id: 'accepted', name: 'Прийнято' },
			{ id: 'rejected', name: 'Відхилено' },
		]}
	/>,
	<TextInput label="За ім'ям" source='name' defaultValue='user' />,
	<TextInput label='За поштою' source='email' />,
	<TextInput label='За телефоном' source='phone' />,
	<TextInput label='За країною' source='country' />,
	<TextInput label='За містом' source='region' />,
]

const ListActions = () => (
	<TopToolbar>
		<FilterButton className='filter' filters={postFilters} />
		<ExportButton label='Експорт' />
	</TopToolbar>
)

const TagFilter = () => (
	<Stack direction='row' justifyContent='space-between' alignItems='end'>
		<FilterForm filters={postFilters} />
	</Stack>
)

const BulkActionButtons = () => {
	return (
		<Box mb={1}>
			<UpdateStatusButton status={'accepted'} resource={'connect_group'} />
			<UpdateStatusButton status={'rejected'} resource={'connect_group'} />
			<UpdateStatusButton status={'pending'} resource={'connect_group'} />
			<BulkDeleteButton label='Видалити' />
		</Box>
	)
}

const ConnectRequestsList = () => {
	return (
		<List
			title='Заявки до конект групи'
			sx={{ tableLayout: 'fixed' }}
			actions={<ListActions />}
			filters={<TagFilter />}
			sort={{ field: 'created_at', order: 'DESC' }}
		>
			<Datagrid
				bulkActionButtons={<BulkActionButtons />}
				rowClick='edit'
				sx={{
					width: '100%',
					backgroundColor: '#f5f5f5',
				}}
			>
				<StatusField label='Статус' />
				<DateField label='Відправлено' source='created_at' />
				<TextField label='ПІБ' source='name' />
				<EmailField label='Пошта' source='email' />
				<TextField label='Телефон' source='phone' />
				<TextField label='Країна' source='country' />
				<TextField label='Місто' source='region' />
				<ReferenceField label='Відправник' source='user_id' reference='users'>
					<TextField source='name' />
				</ReferenceField>
				<ReferenceField
					label='Конект група'
					source='group_id'
					reference='connect_group_list'
				>
					<TextField source='name' />
				</ReferenceField>
			</Datagrid>
		</List>
	)
}

export default ConnectRequestsList
