import {
  Edit,
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  DateInput,
  TextInput,
  ReferenceField,
  required,
} from 'react-admin';

import Box from '@mui/material/Box';

export const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton variant="contained" color="secondary" />
  </Toolbar>
);

const Title = () => {
  const record = useRecordContext();
  return <span> {record?.name} </span>;
};

const CustomToolBar = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton label="Зберегти" />
      <DeleteButton label="Видалити" />
    </Toolbar>
  );
};

const CoachRequestsEdit = () => {
  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flex="row" width="100%">
            <DateInput sx={{ ml: 3 }} label="Відправлено" disabled source="created_at" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3 }} label="ПІБ" disabled source="name" />
            <TextInput sx={{ ml: 3 }} label="Пошта" disabled source="email" />
            <TextInput sx={{ ml: 3 }} label="Телефон" disabled source="phone" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <TextInput sx={{ ml: 3, width: '100%' }} label="Повідомлення" disabled multiline source="message" />
          </Box>
          <Box display="flex" flex="row" width="100%">
            <ReferenceField source="coach_id" reference="users">
              <TextInput
                sx={{ ml: 3, width: '100%' }}
                label="Пастор"
                source="name"
                disabled
              />
            </ReferenceField>
          </Box>
          <Box display="flex" flex="row" width="100%">
          <SelectInput
            label="Статус"
            sx={{ mx: 3 }}
            source="status"
            choices={[
              { id: 'pending', name: 'Очікування' },
              { id: 'accepted', name: 'Оброблено' },
            ]}
            validate={required()}
          />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default CoachRequestsEdit;
