import { FC } from 'react';
import { SelectInput, required } from 'react-admin';

const VideoStatusInput: FC<{ onChange?: (e: any) => void }> = ({ onChange }) => {
  const connectGroupsList = [
    { id: 'archive', name: 'Запис' },
    { id: 'upcoming', name: 'Заплановано' },
    { id: 'live', name: 'Эфір' },
  ];

  return (
    <SelectInput
      label="Статус"
      sx={{ width: '100%' }}
      source="status"
      choices={connectGroupsList}
      // validate={required()}
      onChange={onChange}
    />
  );
};

export default VideoStatusInput;
