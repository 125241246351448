import { Box, Chip } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { FunctionField, useRecordContext } from 'react-admin';

const VideoStatusField: FC<{ label: string }> = ({ label }) => {
  const { status, shown, is_banner } = useRecordContext();

  const [statusChipColor, setStatusChipColor] = useState('#F8BA03');
  const [statusChipText, setStatusChipText] = useState('Запис');

  const [shownChipColor, setShownChipColor] = useState('rgb(34, 172, 106)');
  const [shownChipText, setShownChipText] = useState('Показується');

  useEffect(() => {
    if (is_banner) {
      setStatusChipColor('rgb(128,128,128)');
    }
    switch (status) {
      case 'archive':
        setStatusChipText('Запис');
        setStatusChipColor('rgb(69, 71, 78)');
        break;
      case 'upcoming':
        setStatusChipText('Заплановано');
        setStatusChipColor('rgb(34, 172, 106)');
        break;
      case 'live':
        setStatusChipText('Эфір');
        setStatusChipColor('rgb(187, 43, 43)');
        break;
        // case 'banner':
        //   setStatusChipText('Баннер');
        //   setStatusChipColor('rgb(128,128,128)');
        break;
      default:
        break;
    }
  }, [status]);

  // useEffect(() => {
  //   if (shown) {
  //     setShownChipText('Показується');
  //     setShownChipColor('rgb(34, 172, 106)');
  //   } else {
  //     setShownChipText('Не показується');
  //     setShownChipColor('rgb(187, 43, 43)');
  //   }
  // }, [shown]);

  return (
    <FunctionField
      label={label}
      render={() => (
        <Box display="flex" flexDirection="column" gap={1}>
          <Chip
            label={is_banner ? 'Баннер' : statusChipText}
            sx={{
              background: statusChipColor,
              borderRadius: 2,
              color: 'white',
              fontWeight: 600,
            }}
          />
          {/* <Chip
            label={shownChipText}
            sx={{
              background: shownChipColor,
              borderRadius: 2,
              color: 'white',
              fontWeight: 600,
            }}
          /> */}
        </Box>
      )}
    />
  );
};

export default VideoStatusField;
