import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import {
  Button,
  DateInput,
  DeleteButton,
  Edit,
  ImageField,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useDataProvider,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
  useUpdateMany,
} from 'react-admin'

import { Alert, Button as MuiButton } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCollectionQuery } from '../../hooks/useCollectionQuery'
import {
  UploadVideoSnippet,
  db,
  download,
  storageRef,
} from '../../lib/firebase'

const Title = () => {
  const record = useRecordContext()
  return <span> {record?.name} </span>
}

const CustomToolBar: FC<{
  logoSrc: string
  logoBlob: Blob | null
}> = ({ logoBlob }) => {
  const { id, main_coach_id: old_main_coach_id } = useRecordContext()
  const { getValues } = useFormContext()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()

  // Дістаємо всі чати де юзер є у списку
  const { data, error } = useCollectionQuery(
    'conversations',
    query(
      collection(db, 'conversations'),
      orderBy('updatedAt', 'desc'),
      where('users', 'array-contains', old_main_coach_id)
    )
  )

  // Дістаємо всі групи в зручному форматі
  const chats =
    data?.docs.map(item => ({
      id: item.id,
      ...item.data(),
    })) || []

  console.log('old_main_coach_id', old_main_coach_id)

  const { data: Users, isLoading: ConnectUsersLoading } = useGetList('users')

  const [update, { isLoading }] = useUpdate()
  const [updateMany, { isLoading: isLoadingMany }] = useUpdateMany()
  const UpdateHandler = async () => {
    const { name, name_en, main_coach_id, logo } = getValues()
    console.log('new coach id', main_coach_id)

    if (main_coach_id === null) {
      //  Update coach coach_connect_id in Hasura DB
      await update('users', {
        id: old_main_coach_id,
        data: { coach_connect_id: null },
      })
    }

    let logoSrc = logo || ''

    const generateBannerString = () => {
      const id = Math.random().toString(36).substring(2, 12)
      return `Banner-${id}`
    }

    // Logo
    if (logoBlob) {
      const snapshot = await UploadVideoSnippet(
        generateBannerString(),
        logoBlob
      )
      logoSrc = await download(storageRef(snapshot.metadata.fullPath))
    }

    if (!name) {
      notify('Введіть назву українською', { type: 'error' })
      return
    } else if (!name_en) {
      notify('Введіть назву англійською', { type: 'error' })
      return
    }

    const connectGroupUser = Users?.filter(
      el => el.connect_id === id && el.access_level !== 'coach'
    )!

    const ChangeToId: any[] = []
    const ChangeToNull: any[] = []
    const usersForChangingCoach = []

    // for ( const coach of currentCoaches ) {
    //   coach.enabled ? ChangeToId.push(coach.user_id) : ChangeToNull.push(coach.user_id);
    // }

    for (const user of connectGroupUser) {
      usersForChangingCoach.push(user.id)
    }

    try {
      update('connect_group_list', {
        id,
        data: { name, name_en, main_coach_id, logo: logoSrc },
      })

      if (id) {
        await updateDoc(doc(db, 'conversations', String(id)), {
          coach: { id: main_coach_id },
          group: {
            coachName: '',
            coachId: main_coach_id,
            connectGroupName: name,
            connectGroupNameEN: name_en,
            groupImage: logoSrc,
            groupId: id,
          },
          users: arrayRemove(old_main_coach_id), // remove old_main_coach_id
          updatedAt: serverTimestamp(),
        })

        // If you want to add the new main_coach_id after removing the old one
        await updateDoc(doc(db, 'conversations', String(id)), {
          users: arrayUnion(main_coach_id), // add new main_coach_id
          updatedAt: serverTimestamp(),
        })

        // Встановлюємо users - [] всім іншим чатам з лідером якщо лідер змінив групу
        // chats.map(async (chat: any) => {
        // 	const userDocRef = doc(db, 'conversations', chat.id)
        // 	const userDocSnap = await getDoc(userDocRef)

        // 	if (
        // 		userDocSnap.exists() &&
        // 		chat.users.includes(old_main_coach_id) &&
        // 		chat.id !== id
        // 	) {
        // 		await updateDoc(userDocRef, {
        // 			users: [],
        // 			updatedAt: serverTimestamp(),
        // 		})
        // 	}
        // })
      }

      //  Update coach coach_connect_id in Hasura DB
      await update('users', {
        id: old_main_coach_id,
        data: { coach_connect_id: null },
      })

      //  Update coach coach_connect_id in Hasura DB
      await update('users', {
        id: main_coach_id,
        data: { coach_connect_id: id },
      })

      if (ChangeToId.length !== 0) {
        updateMany('users', {
          ids: ChangeToId,
          data: { connect_id: id },
        })
      }

      if (ChangeToNull.length !== 0) {
        updateMany('users', {
          ids: ChangeToNull,
          data: { connect_id: null, coach_connect_id: null },
        })
      }

      if (usersForChangingCoach.length !== 0) {
        updateMany('users', {
          ids: usersForChangingCoach,
          data: { coach_id: main_coach_id },
        })
      }

      redirect('/connect_group_list')

      setTimeout(() => {
        refresh()
      }, 1000)
    } catch (e) {
      notify('Помилка!', { type: 'error' })
      console.log(e)
    }
  }

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        label='Зберегти'
        disabled={isLoading && isLoadingMany && ConnectUsersLoading}
        size='medium'
        onClick={UpdateHandler}
      />
      <DeleteButton label='Видалити' />
    </Toolbar>
  )
}

const CoachInput = () => {
  const { id } = useRecordContext()
  const [coachUsers, setCoachUsers] = useState<{ id: string; name: string }[]>(
    []
  )
  const dataProvider = useDataProvider()

  useEffect(() => {
    dataProvider
      .getList('users', {
        pagination: {
          page: 1,
          perPage: 100000,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
        filter: undefined,
      })
      .then(({ data }) => {
        const coachUsers = data.filter(el => el.access_level === 'coach')

        const array: { id: string; name: string }[] = []

        for (const coach of coachUsers) {
          array.push({ id: coach.id, name: coach.name })
        }

        setCoachUsers(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <SelectInput
      sx={{ ml: 3, width: '42%' }}
      label='Лідер'
      source='main_coach_id'
      choices={coachUsers}
    />
  )
}
const ConnectGroupEdit = () => {
  const [logoSrc, setLogoSrc] = useState('')

  const [logoBlob, setLogoBlob] = useState<Blob | null>(null)

  const [blobError, setBlobError] = useState<string>('')

  const LogoInputRef = useRef<HTMLInputElement>(null)

  const handleUploadLogo = (event: any) => {
    const img = new Image()
    img.src = URL.createObjectURL(event.target.files[0])

    img.onload = () => {
      const { height, width } = img
      setLogoSrc(img.src)
      setLogoBlob(event.target.files[0])
    }
  }
  const ToolBarItem = () => {
    return <CustomToolBar logoSrc={logoSrc ?? null} logoBlob={logoBlob} />
  }

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<ToolBarItem />}>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flex='row' width='100%'>
            <DateInput
              sx={{ ml: 3 }}
              label='Дата створення'
              disabled
              source='created_at'
            />
            <TextInput
              sx={{ ml: 3, width: '22vw' }}
              label='ID'
              disabled
              source='id'
            />
          </Box>
          <Box display='flex' flex='row' width='100%'>
            <TextInput
              sx={{ ml: 3 }}
              label='Назва конект групи Укр'
              source='name'
              validate={required()}
            />
            <TextInput
              sx={{ ml: 3 }}
              label='Назва конект групи Eng'
              source='name_en'
              validate={required()}
            />
          </Box>
          <Box display='flex' flex='row' width='100%'>
            <CoachInput />
          </Box>
          <Box sx={{ ml: 3 }}>
            <input
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              ref={LogoInputRef}
              onChange={handleUploadLogo}
            />
            {logoBlob ? (
              <img
                style={{ width: '100%', height: '100%' }}
                src={URL.createObjectURL(logoBlob)}
                alt={''}
              />
            ) : (
              <ImageField
                sx={{
                  '& .RaImageField-image': {
                    margin: 0,
                    width: '100%',
                    height: '100%',
                  },
                }}
                title='video-snippet'
                source='logo'
              />
            )}

            {blobError && (
              <Alert severity='error' sx={{ mb: 2 }}>
                {blobError}
              </Alert>
            )}

            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              gap={2}
            >
              <MuiButton
                variant='contained'
                sx={{
                  background: '#703eff',
                  lineHeight: '18px',
                  '&:hover': {
                    background: '#703eff',
                  },
                }}
                onClick={() => {
                  if (LogoInputRef.current) {
                    LogoInputRef.current.click()
                  }
                }}
              >
                Змінити лого
              </MuiButton>
            </Box>
          </Box>
          {/* <Box display="flex" flex="row" width="100%">
            <CoachSelectCheckbox choosedCoaches={choosedCoaches} setChoosedCoaches={e => setChoosedCoaches(e)}/>
          </Box> */}
        </Box>
      </SimpleForm>
    </Edit>
  )
}

export default ConnectGroupEdit
